<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
            @onPageChange="onPageChange" :queryParam="queryParam">

            <div slot="buttonGroup" class="filter-box">
                <div class="input-option">
                    <el-date-picker v-model="SearchLogTime" type="month" :editable="false" :clearable="false"
                      value-format="yyyy-MM-dd HH:mm:ss"  placeholder="选择日期">
                    </el-date-picker>
                </div>
                <el-button type="primary" size="small" @click="searchQueueLog" class="fb-search"
                    v-right-code="'Sysqueuelog:Searchsysqueueloglist'">查询</el-button>
                <el-button type="primary" size="small" @click="retrySysLogs" class="fb-search"
                    v-right-code="'Sysqueuelog:Sendqueue'">重发</el-button>
                <br />
            </div>
            <p slot="elList">
                <el-table ref="queueLogListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
                    highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col, index, count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
                        :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
                        :fixed="index == 0 && (!config || !config.isDetailDisplay)"
                        :min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
                        v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName === 'QueueType'"><el-button type="text"
                                    @click="getQueueLog(scope.row)"
                                    style="text-decoration:underline;color:#1874CD;cursor:pointer">{{
                                        scope.row[col.FieldName] }}</el-button></span>
                            <span v-else> {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>

import df from '../../../../../scripts/displayformat.js';

export default {
    mounted() {
        this.SearchLogTime = df.FormatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
        this.Event.$on("reloadQueueLogPageList", () => this.reloadPageList());
        this.initialize();
    },
    data() {
        return {
            SearchLogTime: '',
            multipleSelection: [],
            queryParam: {
                PageIndex: 1,
                PageSize: 10
            },
            dataSource: {
                ColDefs: {},
                Result: [],
                TotalCount: 0
            }
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        }
    },
    methods: {
        searchQueueLog: function () {
            this.onPageChange(this.queryParam);
        },
        reloadPageList() {
            this.initialize();
        },
        initialize() {
            this.onPageChange(this.queryParam);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.$emit("onCheckRow", this.multipleSelection);
        },
        onPageChange(param) {
            var _this = this;
            this.queryParam = param;
            if (this.SerrchLogTime == null || this.SerrchLogTime == '') this.SerrchLogTime = Date.now();
            param.PageParams = { SearchLogTime: _this.SearchLogTime };
            this.$ajax.send("omsapi/sysqueuelog/searchsysqueueloglist", "post", param, data => {
                this.dataSource = data;
            });
        },
        onDataSourceChange(ds) {
            var _this = this;
            _this.dataSource = {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            };
            _this.$nextTick(function () {
                _this.dataSource = ds;

            });

        },
        getQueueLog(row) {
            var _this = this;
            this.$ajax.send("omsapi/sysqueuelog/getqueuelogbyid", "get", { id: row.Id, date: this.SearchLogTime }, (data) => {
                this.Event.$emit("clearEditQueueLogForm");
                this.onChangeEditDataSource(data.Result);
            });
        },
        retrySysLogs() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox("未选择队列日志");
                return false;
            }

            var _this = this;
            var logIDs = _this.Utils.selectionsToArr(_this.multipleSelection, "Id");

            var retryLogDTO = { ids: logIDs, date: this.SearchLogTime };
            //console.log(retryLogDTO);
            this.$ajax.send("omsapi/sysqueuelog/sendqueue", "post", retryLogDTO, (data) => {
                if (data.IsSuccess) {
                    _this.Utils.messageBox("重发成功", "success");
                    _this.searchQueueLog();
                } else {
                    _this.Utils.messageBox(data.OperationDesc, "error");
                }
            });
        },
        onChangeEditDataSource(data) {
            this.config.isDetailDisplay = true;
            this.$emit("onChangeEditDataSource", data);
        }
    }
}
</script>
<style></style>